export const ROUTES_FOR_G_TAG = {
  'blog/posts/puedo-obtener-un-credito-infonavit': {
    id: 'GTM-PX4TQQJ4'
  },
  'blog/posts/hablemos-de-los-creditos-que-ofrece-infonavit': {
    id: 'GTM-K679NRG5'
  },
  'blog/posts/hablemos-sobre-los-puntos-infonavit-lo-que-necesitas-saber-2': {
    id: 'GTM-NSV5WTSF'
  },
  'blog/posts/todo-lo-que-tienes-que-saber-sobre-el-infonavit': {
    id: 'GTM-WJJWDCXK'
  },
  'blog/posts/5-cosas-que-debes-saber-al-momento-de-comprar-una-propiedad-2': {
    id: 'GTM-WKN4K2JG'
  }
}
