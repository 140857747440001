import { useEffect, useState } from 'react'
import { ROUTES_FOR_G_TAG } from '../../utils/routesForGTag'

const GoogleTagScript = () => {
  const [currentRoute, setCurrentRoute] = useState<string | null>(null)

  useEffect(() => {
    // only run on client side
    if (typeof window !== 'undefined') {
      const route = window.location.pathname.replace(/^\//, '')
      setCurrentRoute(route)
    }
  }, [])

  useEffect(() => {
    if (currentRoute) {
      const gtmData =
        ROUTES_FOR_G_TAG[currentRoute as keyof typeof ROUTES_FOR_G_TAG]

      if (gtmData && gtmData.id) {
        const script = document.createElement('script')
        script.id = 'gtag'
        script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmData.id}');
        `
        document.head.appendChild(script)

        const noscript = document.createElement('noscript')
        noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmData.id}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        document.body.appendChild(noscript)
      }
    }
  }, [currentRoute])

  return null
}

export default GoogleTagScript
